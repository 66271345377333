import React, { useEffect } from "react";

import withFirebase from "../../hoc/withFirebase";

import "../../static/scss/components/_errors.scss";

export default withFirebase(function Error404(props) {
  const { analytics } = props.firebase;

  useEffect(() => {
    document.title = "Mayo - Page Not Found";
    analytics.logEvent("page_view");
  }, [analytics]);

  return (
    <div className="error-404">
      <h1>Page Not Found</h1>
      <h2>Why am I seeing this?</h2>
      <p>There are a few potential reasons:</p>
      <ol>
        <li>You entered a wrong URL.</li>
        <li>We haven't finished setting this up yet.</li>
      </ol>
      <br />
      <a href="https://www.heymayo.com" rel="nofollow">
        Go Back
      </a>
    </div>
  );
});
